<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					@submit.native.prevent
					:model="searchForm"
        >
          <el-form-item label="姓名:">
            <el-input
							v-model.trim="searchForm.studentname"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
					<el-form-item label="手机:">
					  <el-input
							v-model.trim="searchForm.mobile"
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="学校:">
					  <el-input
							v-model.trim="searchForm.collegename"
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="教学点:">
					  <el-input
							v-model.trim="searchForm.schoolorgname"
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="专业:">
					  <el-input
							v-model.trim="searchForm.majorname"
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="分公司:">
					  <el-input
							v-model.trim="searchForm.companyorgname"
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
          <el-form-item label="退费:">
            <el-select
              v-model="searchForm.feebackflag"
              placeholder="请选择"
							@keyup.enter.native="seach"
							@visible-change="visibleType"
							ref="selectref"
              clearable
            >
              <el-option
                v-for="item in feebackflags"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="层次名称:">
            <el-input
							v-model.trim="searchForm.levelval"
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
					<el-form-item label="登账月份:">
					  <el-input
							v-model.trim="searchForm.month"
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="收据编号:">
					  <el-input
							v-model.trim="searchForm.shoujucodes"
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="入学年级:">
					  <el-input
							v-model.trim="searchForm.grade"
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="缴费学年:">
					  <el-input
							v-model.trim="searchForm.iyear"
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="跟进人:">
					  <el-input
							v-model.trim="searchForm.followusername"
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					</el-form-item>
					<el-form-item label="优惠:">
					  <el-input
							v-model.trim="searchForm.discountfee"
							@keyup.enter.native="seach"
							clearable />
					</el-form-item>
					<el-form-item label="是否有减免：">
					  <el-select
							v-model="searchForm.isextrasubmoney"
							@keyup.enter.native="seach"
							@visible-change="visibleisextrans"
							ref="isextrans"
							clearable>
					   <el-option label="否" value="0" />
					   <el-option label="是" value="1" />
					  </el-select>
					</el-form-item>
					<el-form-item label="是否欠费：" prop="isarrears">
					  <el-select
							v-model="searchForm.isarrears"
							@keyup.enter.native="seach"
							@visible-change="visibleisarrears"
							ref="isarrears"
							clearable>
					    <el-option label="否" value="0" />
					    <el-option label="是" value="1" />
					  </el-select>
					</el-form-item>
					<el-form-item label="是否有优惠：" prop="isdiscountfee">
					  <el-select
							v-model="searchForm.isdiscountfee"
							@keyup.enter.native="seach"
							@visible-change="visibleIsdis"
							ref="isdiscount"
							clearable>
					    <el-option label="否" value="0" />
					    <el-option label="是" value="1" />
					  </el-select>
					</el-form-item>
					<el-form-item label="是否有其他：" prop="isotherfee">
					  <el-select
							v-model="searchForm.isotherfee"
							@keyup.enter.native="seach"
							@visible-change="visibleIsother"
							ref="isotherfee"
							clearable>
					    <el-option label="否" value="0" />
					    <el-option label="是" value="1" />
					  </el-select>
					</el-form-item>
					<el-form-item label="分页大小：">
            <el-input-number
              class="inputNumber"
              v-model="pageinfo.pagesize"
              :min="10"
              :max="100000"
              label="描述文字"
              @keyup.enter.native="seach"
            >
          </el-input-number>
					</el-form-item>
					<el-form-item label="项目类型:">
					  <el-select
					    v-model="searchForm.projecttype"
							@keyup.enter.native="seach"
							@visible-change="visibleprojecttype"
							ref="projecttype"
					    placeholder="请选择"
					    clearable
					  >
					    <el-option
					      v-for="item in projecttypeList"
					      :key="item.key"
					      :label="item.val"
					      :value="item.val"
					    >
					    </el-option>
					  </el-select>
					</el-form-item>
					<el-form-item label="费用类型:">
					  <el-select
					    v-model="searchForm.itemname"
							@keyup.enter.native="seach"
							@visible-change="visibleName"
							ref="nameref"
					    placeholder="请选择"
					    clearable
					  >
					    <el-option
					      v-for="item in feetype2List"
					      :key="item.key"
					      :label="item.val"
					      :value="item.val"
					    >
					    </el-option>
					  </el-select>
					</el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >展开</el-button
        >
      </div>
    </div>
    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      style="width: 100%;"
			border
			stripe
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
      :cell-style="{ padding: 0 }"
      @selection-change="handleSelectionChange"
			:height="tableHeight"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="姓名/手机" align="center" :show-overflow-tooltip="true" width="180">
        <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row.qianzaiid)" type="text">
           {{ scope.row.studentname }} {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        label="年级/院校/专业"
        align="center"
				width="230"
      >
				<template slot-scope="scope">
						{{scope.row.grade}} {{scope.row.collegename}} {{scope.row.majorname}}
				</template>
			</el-table-column>
      <el-table-column label="学年" prop="iyear" align="center" width="50"/>
			<el-table-column
			  label="费用类型"
			  prop="itemname"
			  align="center"
			  width="120"
			>
			</el-table-column>
      <el-table-column
        label="应收"
        prop="money"
        align="center"
				width="60"
      ></el-table-column>
      <el-table-column
        label="实收"
        prop="recvitemmoney"
        align="center"
				width="60"
      ></el-table-column>
      <el-table-column label="减免" prop="extrasubmoney" align="center" width="60">
      </el-table-column>
      <el-table-column label="优惠" prop="discountfee" align="center" width="60">
      </el-table-column>
      <el-table-column
        label="学位"
        prop="studentfee"
        align="center"
        width="60"
      >
      </el-table-column>
      <el-table-column label="其它" prop="otherfee" align="center" width="60">
      </el-table-column>
			<el-table-column label="退费" prop="feebackmoney" align="center" width="60">
			</el-table-column>
			<el-table-column label="自缴" prop="paytoschool" align="center" width="60">
			</el-table-column>

      <el-table-column label="欠费" align="center" width="60">
        <template slot-scope="scope">
          {{
            scope.row.money -
            0 -
            scope.row.recvitemmoney -
            0 -
            scope.row.extrasubmoney -
            0 -
            scope.row.discountfee -
            0 -
            scope.row.studentfee -
            0 -
            scope.row.otherfee -
            0 +
            scope.row.feebackmoney -
            0-
						scope.row.paytoschool
          }}
        </template>
      </el-table-column>
			<el-table-column label="付院校" prop="tuition" align="center" />
			<el-table-column label="付总部" prop="managementfee" align="center" />
      <el-table-column
        label="收据号"
        prop="shoujucodes"
        align="center"
        width="140"
				:show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        label="层次"
        prop="levelval"
        align="center"
      ></el-table-column>
      <el-table-column
      	label="形式"
      	prop="studytypeval"
      	align="center"
      ></el-table-column>
      <el-table-column
        label="跟进人"
        prop="followusername"
        align="center"
        width="140"
      >
      </el-table-column>
			<el-table-column
			  label="登账月份"
			  prop="month"
			  align="center"
			  width="100"
			>
			</el-table-column>
      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
        width="100"
      >
      </el-table-column>
			<el-table-column
			  label="ID"
			  prop="id"
			  align="center"
				width="100px"
			>
			</el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>
	<!-- 	名单校验 -->
		<el-dialog
			class="btn-2b5a95"
			title="名单校验"
			v-dialogDrag
			:visible.sync="showImportModal"
			v-if="showImportModal"
			:close-on-click-modal='false'
			append-to-body
			width="35%"
		>
			<el-form
				:model="dynamicValidateForm"
				ref="dynamicValidateForm"
				class="demo-dynamic form-item-w-100"
				status-icon
				:rules="rules"
			>
				<el-form-item prop="scorefile" label="导入文件">
					<el-upload
						class="upload-demo"
						action=""
						:on-remove="onScorefileRemove"
						:file-list="fileList"
						multiple=""
						ref="upload"
						accept=".xls, .xlsx"
						name="file"
						:on-change="handleChange"
					>
					<el-button slot="trigger" type="primary">选择文件</el-button>
					<br/>
					<el-button
						type="success"
						@click="downloadTemplate('/download/财务管理/院校名单校验模板.xls')"
					>名单校验模板下载</el-button>
					<div slot="tip" class="el-upload__tip" v-if="!resTipC">
						只能上传以.xls为后缀的文件
					</div>
					<div v-else>
						<h2>提示：</h2>
						<div v-html="resTipC" style="color: red;"></div>
					</div>
					</el-upload>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button
					type="primary"
					  @click="submitImportForm('dynamicValidateForm')"
				>提交</el-button>
			</div>
		</el-dialog>
    <!-- 新增 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
			width="60%"
			:title="this.dialogTitle"
			:close-on-click-modal='false'
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-50"
        status-icon
        label-width="140px"
        :rules="rules"
      >
        <h3>学生信息</h3>
        <el-form-item label="学生姓名：" prop="studentname">
          <el-input
            v-model="dynamicValidateForm.studentname"
            disabled
          ></el-input>
          <el-button
						:disabled="disbtn"
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(studentregister_dialogresult, 'showStudentNameModal')
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="层次类型：">
          <el-input v-model="dynamicValidateForm.levelval" disabled></el-input>
        </el-form-item>
        <el-form-item label="学校：">
          <el-input
            v-model="dynamicValidateForm.collegename"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="学 年：" prop="iyear">
					<el-select
					  v-model="dynamicValidateForm.iyear"
					  placeholder="请选择"
					  clearable
					>
					 <el-option label="" value="1" />
					 <el-option label="" value="2" />
					 <el-option label="" value="3" />
					 <el-option label="" value="4" />
					 <el-option label="" value="5" />
					</el-select>
        </el-form-item>
        <el-form-item label="入学年级：">
          <el-input v-model="dynamicValidateForm.grade" disabled></el-input>
        </el-form-item>
        <el-form-item label="专业：">
          <el-input v-model="dynamicValidateForm.majorname" disabled></el-input>
        </el-form-item>
        <el-form-item label="所属教学点：">
          <el-input
            v-model="dynamicValidateForm.schoolorgname"
            disabled
          ></el-input>
        </el-form-item>
        <h3>注册明细</h3>
        <el-form-item label="二级目录：" prop="type2name">
          <el-input v-model="dynamicValidateForm.type2name" disabled></el-input>
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(moneyintype2_dialogresult, 'showMoneyinitemModal2')
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="收入明细：" prop="itemname">
          <el-input v-model="dynamicValidateForm.itemname" disabled></el-input>
          <el-button
            type="primary"
            size="small"
            class="select-btn"
            @click="
              onSelectData(moneyinitem_dialogresult, 'showMoneyinitemModal')
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="应收：" prop="money">
          <el-input v-model="dynamicValidateForm.money" onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" clearable/>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          >提交</el-button
        >
      </span>
    </el-dialog>

    <!-- 选择学生 表格 -->
    <el-dialog
      class="btn-2b5a95"
      width="1000px"
			title="选择学生："
			v-dialogDrag
      @close="onCloseSelect"
      :visible.sync="showStudentNameModal"
      v-if="showStudentNameModal"
			:close-on-click-modal='false'
      append-to-body
    >
      <el-button type="primary" @click="onConfirmStudent">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="学生姓名:" prop="studentname">
              <el-input
								v-model.trim="selectSearchForm.studentname"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
            <el-form-item label="手机号码:" prop="mobile">
              <el-input
								v-model.trim="selectSearchForm.mobile"
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				stripe
				:key="key"
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
					width="100px"
        ></el-table-column>
        <el-table-column
          label="学生姓名"
          prop="studentname"
          align="center"
        ></el-table-column>
        <el-table-column
          label="手机号码"
          prop="mobile"
          align="center"
          width="140"
        ></el-table-column>
        <el-table-column
          label="学年"
          prop="iyear"
          align="center"
        ></el-table-column>
        <el-table-column
          label="省内/省外"
          prop="areaval"
          align="center"
					width="100px"
        ></el-table-column>
        <el-table-column
          label="入学年级"
          prop="grade"
          align="center"
        ></el-table-column>
        <el-table-column
          label="类别"
          prop="keleival"
          align="center"
        ></el-table-column>
        <el-table-column
          label="层次"
          prop="levelval"
          align="center"
        ></el-table-column>
        <el-table-column
          label="院校"
          prop="collegename"
          align="center"
					width="200px"
        ></el-table-column>
        <el-table-column
          label="专业"
          prop="majorname"
          align="center"
					width="180px"
        ></el-table-column>
        <el-table-column
          label="学习方式"
          prop="studytypeval"
          align="center"
        ></el-table-column>
        <el-table-column label="注册状态" align="center">
          <template slot-scope="scope">
            {{ isflag[scope.row.regflag] }}
          </template>
        </el-table-column>
        <el-table-column
          label="教学点"
          prop="schoolorgname"
          align="center"
					width="120px"
        ></el-table-column>
        <el-table-column
          label="项目类型"
          prop="projecttype"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({}, selectSearchForm))
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 注册收费 -->
    <el-dialog
      class="btn-2b5a95"
      title="注册收费："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showRegisterModal"
      v-if="showRegisterModal"
      append-to-body
      width="1300px"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-33"
        status-icon
        label-width="100px"
        :rules="rules"
      >
        <h3>学生信息</h3>
        <el-form-item prop="companyorgid" label="分公司：">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgid" label="教学点：">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名：">
          <el-input
            v-model="dynamicValidateForm.studentname"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="院校：">
          <el-input
            v-model="dynamicValidateForm.collegename"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="专业：">
          <el-input v-model="dynamicValidateForm.majorname" disabled></el-input>
        </el-form-item>
        <el-form-item label="缴费学年：">
          <el-input v-model="dynamicValidateForm.iyear" disabled></el-input>
        </el-form-item>
        <h3>收费信息(***请仔细核对每一项)</h3>
        <el-form-item label="预收学位费：" prop="studentfee">
          <el-input v-model.trim="dynamicValidateForm.studentfee" clearable/>
        </el-form-item>
        <el-form-item label="学位费凭证号：" prop="studentshouju">
          <el-input v-model.trim="dynamicValidateForm.studentshouju" clearable/>
        </el-form-item>
        <el-form-item label="预收其它费抵学费：" prop="otherfee">
          <el-input v-model.trim="dynamicValidateForm.otherfee" clearable/>
        </el-form-item>
        <el-form-item label="其它抵学费凭证：" prop="othershouju">
          <el-input v-model.trim="dynamicValidateForm.othershouju" clearable/>
        </el-form-item>
        <el-form-item label="收费项目：" prop="itemname">
          <el-input v-model.trim="dynamicValidateForm.itemname" clearable/>
        </el-form-item>
        <el-form-item label="应收金额：" prop="money">
          <el-input v-model.trim="dynamicValidateForm.money" clearable/>
        </el-form-item>
				<el-form-item label="优惠">
				  <el-select v-model="dynamicValidateForm.discountfee" placeholder="请选择" @change="onCountfee">
				     <el-option v-for="item in getCount"
				                :key="item.id"
				                :label="item.discountamount"
				                :value="{value: item.id, label: item.discountamount}"
				     ></el-option>
				   </el-select>
				</el-form-item>
        <el-form-item label="实收金额：" prop="recvitemmoney">
          <el-input v-model.trim="dynamicValidateForm.recvitemmoney" clearable/>
        </el-form-item>
        <el-form-item label="凭证编号：" prop="shoujucode">
          <el-input
            v-model="dynamicValidateForm.shoujucode"
            disabled
          ></el-input>
          <el-button type="primary" class="select-btn" @click="getshoujuinfo"
            >重新获取</el-button
          >
        </el-form-item>
        <el-form-item label="收款时间：" prop="shoukuantime">
          <el-date-picker
            type="datetime"
            v-model="dynamicValidateForm.shoukuantime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item label="银行/现金：" prop="bankorcash" >
          <el-input
            v-model="bankorcash[dynamicValidateForm.bankorcash]"
            disabled
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="
              onSelectData(account_dialogresult, 'showAccountModal', {
                schoolorgid: dynamicValidateForm.schoolorgid,
              })
            "
            >选择账户</el-button
          >
        </el-form-item>
        <el-form-item label="账户名称：" prop="accountname">
          <el-input
            v-model="dynamicValidateForm.accountname"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="卡号：" prop="cardno">
          <el-input v-model="dynamicValidateForm.cardno" disabled></el-input>
        </el-form-item>
        <el-form-item label="开票说明：" prop="shoukuanremark">
          <el-input v-model.trim="dynamicValidateForm.shoukuanremark" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitRegisterForm('dynamicValidateForm')"
          >提交</el-button
        >
      </span>
    </el-dialog>

    <!-- 补优惠 -->
    <el-dialog
      class="btn-2b5a95"
      title="补优惠："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showDiscountModal"
      v-if="showDiscountModal"
      append-to-body
      width="25%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="补优惠" prop="dissfee">
        	<el-select v-model="dynamicValidateForm.dissfee" placeholder="请选择" @change="changefee">
        	   <el-option v-for="free in getCount"
        	              :key="free.id"
        	              :label="free.discountamount"
        	              :value="{value: free.id, label: free.discountamount}"
        	   ></el-option>
        	 </el-select>
        </el-form-item>
        <el-form-item label="优惠说明" prop="remarks1">
          <el-input v-model.trim="dynamicValidateForm.remarks1" clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onDiscountSumbit('dynamicValidateForm')"
          >提交</el-button
        >
      </span>
    </el-dialog>

    <!-- 补减免 -->
    <el-dialog
      class="btn-2b5a95"
      title="补减免："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showExtrasubModal"
      v-if="showExtrasubModal"
      append-to-body
      width="25%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon

        :rules="rules"
      >
        <el-form-item label="减免金额" prop="extrasubmoney2">
          <el-input v-model.trim="dynamicValidateForm.extrasubmoney2" clearable/>
        </el-form-item>
        <el-form-item label="减免说明" prop="remarks2">
          <el-input v-model.trim="dynamicValidateForm.remarks2" clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onExtrasubSumbit('dynamicValidateForm')"
          >提交</el-button
        >
      </span>
    </el-dialog>

    <!-- 补其它 -->
    <el-dialog
      class="btn-2b5a95"
      title="补其它："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showOtherfeeModal"
      v-if="showOtherfeeModal"
      append-to-body
      width="25%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon

        :rules="rules"
      >
        <el-form-item label="其它费抵学费" prop="otherfee2">
          <el-input v-model.trim="dynamicValidateForm.otherfee2" clearable/>
        </el-form-item>
        <el-form-item label="其 它 凭 证" prop="othershouju2">
          <el-input v-model.trim="dynamicValidateForm.othershouju2" clearable/>
        </el-form-item>
        <el-form-item label="其 它 说 明" prop="remarks3">
          <el-input v-model.trim="dynamicValidateForm.remarks3" clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onOtherfeeSumbit('dynamicValidateForm')"
          >提交</el-button
        >
      </span>
    </el-dialog>

    <!-- 自行缴费 -->
    <el-dialog
      class="btn-2b5a95"
      title="自行缴费："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showSelfPaymentModal"
      v-if="showSelfPaymentModal"
      append-to-body
      width="25%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon

        :rules="rules"
      >
        <el-form-item label="金额" prop="paytoschool2">
          <el-input v-model.trim="dynamicValidateForm.paytoschool2" clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSelfPaymentSumbit('dynamicValidateForm')"
          >提交</el-button
        >
      </span>
    </el-dialog>

    <!-- 申请退费 -->
    <el-dialog
      class="btn-2b5a95"
      title="申请退费："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showFeebackModal"
      v-if="showFeebackModal"
      append-to-body
      width="30%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        label-width="30%"
        :rules="rules"
      >
        <el-form-item label="学生姓名：">
          <el-input
            v-model="dynamicValidateForm.studentname"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="收据编号：">
          <el-input
            v-model="dynamicValidateForm.shoujucode"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="申请退费金额：" prop="feebackmoney">
          <el-input v-model.trim="dynamicValidateForm.feebackmoney" clearable/>
        </el-form-item>
        <el-form-item label="申请退费时间：" prop="feebacktime">
          <el-date-picker
            type="datetime"
            v-model="dynamicValidateForm.feebacktime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item label="申请退费原因" prop="reason">
          <el-input
            type="textarea"
            :rows="6"
            v-model.trim="dynamicValidateForm.reason"
						placeholder="请说明申请退费原因"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onFeebackClick('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择账户 表格 -->
    <el-dialog
      class="btn-2b5a95"
      width="1300px"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择账户："
      :visible.sync="showAccountModal"
      v-if="showAccountModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmAccount">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="账户:" prop="accountname">
              <el-input
								v-model.trim="selectSearchForm.accountname"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  { schoolorgid: dynamicValidateForm.schoolorgid },
                  selectSearchForm
                )
              )"
								clearable />
            </el-form-item>
            <el-form-item label="银行:" prop="bankname">
              <el-input
								v-model.trim="selectSearchForm.bankname"
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    { schoolorgid: dynamicValidateForm.schoolorgid },
								    selectSearchForm
								  )
								)"
								clearable />
            </el-form-item>
            <el-form-item label="卡号:" prop="cardno">
              <el-input
								v-model.trim="selectSearchForm.cardno"
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    { schoolorgid: dynamicValidateForm.schoolorgid },
								    selectSearchForm
								  )
								)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  { schoolorgid: dynamicValidateForm.schoolorgid },
                  selectSearchForm
                )
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="200px"
        />
        <el-table-column
          label="开户名称"
          prop="accountname"
          align="center"
          width="250px"
        />
        <el-table-column label="现金/银行" align="center" width="100px">
          <template slot-scope="scope">
            {{ bankorcash[scope.row.bankorcash] }}
          </template>
        </el-table-column>
        <el-table-column
          label="卡号"
          prop="cardno"
          align="center"
          width="200px"
        />
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
          width="200px"
        />
      </el-table>


      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(
              val,
              Object.assign(
                { schoolorgid: dynamicValidateForm.schoolorgid },
                selectSearchForm
              )
            )
        "
        :current-page="pageinfo.pageindex"
        :page-size="pageinfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 选择支出明细 表格 -->
    <el-dialog
      class="btn-2b5a95"
      width="60%"
			v-dialogDrag
      @close="onCloseSelect"
			:close-on-click-modal='false'
      title="选择支出明细："
      :visible.sync="showMoneyinitemModal"
      v-if="showMoneyinitemModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmMoneyinitem">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="名称:" prop="name">
              <el-input
								v-model.trim="selectSearchForm.name"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        ref="moneyTable"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
        ></el-table-column>
        <el-table-column
          label="名称"
          prop="name"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({}, selectSearchForm))
        "
        :current-page="pageinfo.pageindex"
        :page-size="pageinfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 选择支出二级明细 表格 -->
    <el-dialog
      class="btn-2b5a95"
      width="76%"
			v-dialogDrag
      @close="onCloseSelect"
			:close-on-click-modal='false'
      title="选择支出二级明细："
      :visible.sync="showMoneyinitemModal2"
      v-if="showMoneyinitemModal2"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmMoneyinitem2">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="二级项目:" prop="name">
              <el-input
								v-model.trim="selectSearchForm.name"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
            <el-form-item label="收入类型:" prop="typename">
              <el-input
								v-model.trim="selectSearchForm.typename"
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
								clearable />
            </el-form-item>
            <el-form-item label="收入一级项目:" prop="type1name">
              <el-input
								v-model.trim="selectSearchForm.type1name"
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button @click="handleQuery" type="primary">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="二级项目"
          prop="name"
          align="center"
          width="200"
        ></el-table-column>
        <el-table-column
          label="收入类型"
          prop="typename"
          align="center"
          width="200"
        ></el-table-column>
        <el-table-column
          label="收入一级项目"
          prop="type1name"
          align="center"
          width="230"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({}, selectSearchForm))
        "
        :current-page="pageinfo.pageindex"
        :page-size="pageinfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>
		<el-dialog
			class="btn-2b5a95"
			width="1000px"
			v-dialogDrag
			@close="onCloseSelect"
			title="打印收据:"
			:close-on-click-modal='false'
			:visible.sync="shoujuPaper"
			v-if="shoujuPaper"
			append-to-body
		>
			<object class="aaa" id="ReportViewer" name="ReportViewer" type="application/x-grplugin-printviewer" width="900px" height="500px" style="margin: auto;">
			    <param name="ReportURL" :value="this.$url.upload + '/report/registshouju.grf'">
			    <param name="DataURL" value="" >
			    <param name="AutoRun" value=true >
			    <param name="SerialNo" value="4DFB949E066NYS7W11L8KAT53SA177391Q9LZQ094WUT9C9J3813SX8PTQC4ALPB9UAQN6TMA55Q3BN8E5726Z5A839QAD9P6E76TKNK5">
			    <param name="UserName" value="锐浪报表插件本机开发测试注册" >
			</object>
		</el-dialog>
		<div id="RightOffside" class="RightOffside" v-show="showDetailModal">
			<!-- 按钮组 -->
			<div class="Offside_btnBox">
				<div class="Offside_Btn Offside_CloseBtn" @click="handleShow"></div>
				<div class="Offside_Btn"
					v-for="(item,index) in tabList"
					:key="index"
					:class="{active:currentClass === index}"
					@click="toggleTab(index)"
				>
					{{item}}
				</div>
			</div>
			<div class="OrderStatusTips">
				<span class="pull-left text-blue">人员信息</span>
			</div>
			<!-- 切换栏 -->
			<div>
				<div class="OrderContentBox" v-show="currentTab === 0">
					<div class="order-card x-basic abase-card" style="width:800px;background-color: #D1EEEE; margin-bottom: 30px;">
						<div class="top_el">
							基本信息
						</div>
						<el-form>
							<table class="tlist_el" style="width: 100%;" bgcolor="#D1EEEE" cellpadding="5px">
								<tbody>
									<tr>
										<td class="ltd_el">分公司：</td>
										<td class="ltd_tag">{{ vstudentpotential.companyorgname }}</td>
										<td class="ltd_el">教学点：</td>
										<td class="ltd_tag">{{ vstudentpotential.schoolorgname }}</td>
										<td class="ltd_el">流水号：</td>
										<td class="ltd_tag">{{ vstudentpotential.id }}</td>
										<td class="ltd_el">姓名：</td>
										<td class="ltd_tag">{{ vstudentpotential.name }}</td>
									</tr>
									<tr>
										<td class="ltd_el">性别：</td>
										<td class="ltd_tag">{{ vstudentpotential.sexval }}</td>
										<td class="ltd_el">婚否：</td>
										<td class="ltd_tag">{{ vstudentpotential.marryval }}</td>
										<td class="ltd_el">手机：</td>
										<td class="ltd_tag">{{ vstudentpotential.mobile  | phoneFilter()}}</td>
										<td class="ltd_el">手机2：</td>
										<td class="ltd_tag">{{ vstudentpotential.mobile2 }}</td>
									</tr>
									<tr>
										<td class="ltd_el">QQ：</td>
										<td class="ltd_tag">{{ vstudentpotential.qq }}</td>
										<td class="ltd_el">电子邮箱：</td>
										<td class="ltd_tag">{{ vstudentpotential.email }}</td>
										<td class="ltd_el">出生日期：</td>
										<td class="ltd_tag">{{ vstudentpotential.birthday | formatDate("YYYY-MM-DD")}}</td>
										<td class="ltd_el">证件类型：</td>
										<td class="ltd_tag">{{ vstudentpotential.idcardtypeval }}</td>
									</tr>
									<tr>
										<td class="ltd_el">证件号码：</td>
										<td class="ltd_tag">{{ vstudentpotential.idcard }}</td>
										<td class="ltd_el">固定电话：</td>
										<td class="ltd_tag">{{ vstudentpotential.phone }}</td>
										<td class="ltd_el">政治面貌：</td>
										<td class="ltd_tag">{{ vstudentpotential.polival }}</td>
										<td class="ltd_el">民族：</td>
										<td class="ltd_tag">{{ vstudentpotential.nationval }}</td>
									</tr>
									<tr>
										<td class="ltd_el">籍贯：</td>
										<td class="ltd_tag">{{ vstudentpotential.comefrom }}</td>
										<td class="ltd_el">总部给予：</td>
										<td class="ltd_tag">{{ available[vstudentpotential.hqsend] }}</td>
										<td class="ltd_el">意向程度：</td>
										<td class="ltd_tag">{{ vstudentpotential.intentionval }}</td>
										<td class="ltd_el">意向院校：</td>
										<td class="ltd_tag">{{ vstudentpotential.wantcollegename }}</td>
									</tr>
									<tr>
										<td class="ltd_el">意向专业：</td>
										<td class="ltd_tag">{{ vstudentpotential.comefrom }}</td>
										<td class="ltd_el">层次：</td>
										<td class="ltd_tag">{{ vstudentpotential.levelval }}</td>
										<td class="ltd_el">生源来源：</td>
										<td class="ltd_tag">{{ vstudentpotential.fromtypeval }}</td>
										<td class="ltd_el">正式学生：</td>
										<td class="ltd_tag">{{ available[vstudentpotential.studentflag] }}</td>
									</tr>
									<tr>
										<td class="ltd_el">项目类型：</td>
										<td class="ltd_tag">{{ vstudentpotential.projecttypeval }}</td>
										<td class="ltd_el">信息来源：</td>
										<td class="ltd_tag">{{ vstudentpotential.messagefromval }}</td>
										<td class="ltd_el">住址：</td>
										<td class="ltd_tag">{{ vstudentpotential.homeaddr }}</td>
										<td class="ltd_el">邮编：</td>
										<td class="ltd_tag">{{ vstudentpotential.homezipcode }}</td>
									</tr>
									<tr>
										<td class="ltd_el">户口所在地：</td>
										<td class="ltd_tag">{{ vstudentpotential.hukouaddr }}</td>
										<td class="ltd_el">毕业院校：</td>
										<td class="ltd_tag">{{ vstudentpotential.graduateschool }}</td>
										<td class="ltd_el">毕业时间：</td>
										<td class="ltd_tag">
											{{
									      vstudentpotential.graduatetime
									        | formatDate("YYYY-MM-DD")
									    }}</td>
										<td class="ltd_el">学历：</td>
										<td class="ltd_tag">{{ vstudentpotential.eduval }}</td>
									</tr>
									<tr>
										<td class="ltd_el">跟进次数：</td>
										<td class="ltd_tag">{{ vstudentpotential.followcount }}</td>
										<td class="ltd_el">工作类型：</td>
										<td class="ltd_tag">{{ vstudentpotential.jobtype }}</td>
										<td class="ltd_el">专科毕业专业：</td>
										<td class="ltd_tag">{{ vstudentpotential.zhuankemajor }}</td>
										<td class="ltd_el">专科毕业证书号：</td>
										<td class="ltd_tag">{{ vstudentpotential.zhuankeno }}</td>
									</tr>
									<tr>
										<td class="ltd_el">跟进人：</td>
										<td class="ltd_tag">{{ vstudentpotential.followusername }}</td>
										<td class="ltd_el">职业：</td>
										<td class="ltd_tag">{{ vstudentpotential.job }}</td>
										<td class="ltd_el"> 参加工作时间：</td>
										<td class="ltd_tag">
											{{
											  vstudentpotential.jobtime | formatDate("YYYY-MM-DD HH:mm:ss")
											}}
										</td>
										<td class="ltd_el">工作单位：</td>
										<td class="ltd_tag">{{ vstudentpotential.companyname }}</td>
									</tr>
									<tr>
										<td class="ltd_el">客服：</td>
										<td class="ltd_tag">{{ vstudentpotential.kefuusername }}</td>
										<td class="ltd_el">操作人：</td>
										<td class="ltd_tag">{{ vstudentpotential.opusername }}</td>
										<td class="ltd_el"> 录入时间：</td>
										<td class="ltd_tag">
										{{
										  vstudentpotential.inserttime
										    | formatDate("YYYY-MM-DD HH:mm:ss")
										}}
										</td>
										<td class="ltd_el"> 最后操作时间：</td>
										<td class="ltd_tag">
											{{
									      vstudentpotential.lastinserttime
									        | formatDate("YYYY-MM-DD HH:mm:ss")
									    }}</td>
									</tr>
									<tr>
										<td class="ltd_el">区域：</td>
										<td class="ltd_tag">{{ vstudentpotential.regionname }}</td>
										<td class="ltd_el">城市：</td>
										<td class="ltd_tag">{{ vstudentpotential.cityval }}</td>
										<td class="ltd_el"> 微信：</td>
										<td class="ltd_tag">
												{{ vstudentpotential.weixin }}
										</td>
										<td class="ltd_el">最后操作人：</td>
										<td class="ltd_tag">{{ vstudentpotential.lastopusername }}</td>
									</tr>
									<tr>
										<td class="ltd_el">放弃跟进原因：</td>
										<td class="ltd_tag">{{ vstudentpotential.followstatename }}</td>
										<td class="ltd_el">咨询情况：</td>
										<td class="ltd_tag">{{ vstudentpotential.question }}</td>
										<td class="ltd_el"> 转介绍人：</td>
										<td class="ltd_tag">
											{{ vstudentpotential.linkman }}
										</td>
										<td class="ltd_el">合作企业人：</td>
										<td class="ltd_tag">{{ vstudentpotential.enterprisename }}</td>
									</tr>
								</tbody>
							</table>
						</el-form>
					</div>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 1">
					<el-table
						:data="watchDetailList.studentlist"
						:key="key"
						style="width: 100%">
					  <el-table-column
					    label="流水号"
					    prop="id"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column
					    label="学生姓名"
					    prop="qianzainame"
					    align="center"
					    width="150"
					  >
					  </el-table-column>
					  <el-table-column
					    label="手机号"
					    prop="mobile"
					    align="center"
					    width="150"
					  >
							<template slot-scope="scope">
							  {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
							</template>
					  </el-table-column>
					  <el-table-column
					    label="报读年级"
					    prop="grade"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column
					    label="层次"
					    prop="levelval"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column label="总部给予" align="center" width="100">
					    <template slot-scope="scope">
					      {{ available[scope.row.hqsend] }}
					    </template>
					  </el-table-column>
					  <el-table-column
					    label="学习形式"
					    prop="studytypeval"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="类型"
					    prop="baomingtypeval"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="应收"
					    prop="shouldrecvmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="实收"
					    prop="realrecvmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="退费"
					    prop="feebackmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="收据编号"
					    prop="shoujucode"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="跟进人"
					    prop="followusername"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column label="报名时间" align="center" width="100">
					    <template slot-scope="scope">
					      {{ scope.row.inserttime | formatDate("YYYY-MM-DD hh:mm:ss") }}
					    </template>
					  </el-table-column>
					  <el-table-column
					    label="项目类型"
					    prop="projecttype"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="合作机构"
					    prop="organization"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="所属教学点"
					    prop="schoolorgname"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 2">
					<el-table
					  :data="watchDetailList.studentmoneyrecvlist"
						:key="key"
					  style="width: 100%"
					>
					  <el-table-column label="流水号" prop="id" align="center" />
					  <el-table-column
					    label="学生姓名"
					    prop="studentname"
					    align="center"
					  />
					  <el-table-column label="手机号码" prop="mobile" align="center" >
							<template slot-scope="scope">
								{{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
							</template>
						</el-table-column>
					  <el-table-column label="身份证" prop="idcard" align="center" />
					  <el-table-column
					    label="费用类型"
					    prop="feetypeval"
					    align="center"
					  />
					  <el-table-column
					    label="账号名称"
					    prop="accountname"
					    align="center"
					  />
					  <el-table-column label="金额" prop="recvmoney" align="center" />
					  <el-table-column label="缴费时间" prop="recvtime" align="center">
					    <template slot-scope="scope">
					      {{ scope.row.recvtime | formatDate("YYYY-MM-DD hh:mm:ss") }}
					    </template>
					  </el-table-column>
					  <el-table-column
					    label="收据编号"
					    prop="shoujucode"
					    align="center"
					  />
					  <el-table-column
					    label="经手人"
					    prop="insertusername"
					    align="center"
					  />
					  <el-table-column
					    label="所属教学"
					    prop="schoolorgname"
					    align="center"
					  />
					  <el-table-column label="录入时间" align="center">
					    <template slot-scope="scope">
					      {{ scope.row.inserttime | formatDate("YYYY-MM-DD hh:mm:ss") }}
					    </template>
					  </el-table-column>
					  <el-table-column label="冲账标志" prop="invertflag" align="center">
					    <template slot-scope="scope">
					      {{ chongzhanflag[scope.row.invertflag] }}
					    </template>
					  </el-table-column>
					  <el-table-column label="被冲流水" prop="invertid" align="center" />
					  <el-table-column label="冲账时间" align="center">
					    <template slot-scope="scope">
					      {{ scope.row.inverttime | formatDate("YYYY-MM-DD hh:mm:ss") }}
					    </template>
					  </el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 3">
					<el-table
						:data="watchDetailList.techfudaolist"
						:key="key"
						style="width: 100%">
					  <el-table-column
					    label="流水号"
					    prop="id"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column
					    label="学生姓名"
					    prop="studentname"
					    align="center"
					    width="150"
					  >
					  </el-table-column>
					  <el-table-column
					    label="手机号"
					    prop="mobile"
					    align="center"
					    width="150"
					  >

					  </el-table-column>
					  <el-table-column
					    label="班别名称"
					    prop="fudaoclasstypename"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column
					    label="培训类型"
					    prop="fudaotypeval"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column label="报读时间" align="center" width="100">
					    <template slot-scope="scope">
					      {{ scope.row.regdate | formatDate("YYYY-MM-DD hh:mm:ss") }}
					    </template>
					  </el-table-column>
					  <el-table-column
					    label="收款标识"
					    prop="shoukuanremark"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="应收"
					    prop="totalmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="减免金额"
					    prop="extrasubmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="银行/现金"
					    prop="bankorcash"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="实收"
					    prop="recvmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="退费金额"
					    prop="feebackmoney"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="收据编号"
					    prop="shoujucode"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="跟进人"
					    prop="followusername"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="所属教学点"
					    prop="schoolorgname"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 4">
					<el-table
					  :data="watchDetailList.studentregisterlist"
					  style="width: 100%"
						:key="key"
					>
					  <el-table-column
					    label="流水号"
					    prop="id"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column
					    label="姓名"
					    prop="studentname"
					    align="center"
					    width="150"
					  >
					  </el-table-column>
					  <el-table-column
					    label="手机"
					    prop="mobile"
					    align="center"
					    width="150"
					  >
							<template slot-scope="scope">
								{{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
							</template>
					  </el-table-column>
					  <el-table-column
					    label="学号"
					    prop="code"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column
					    label="身份证"
					    prop="studentidcard"
					    align="center"
					    width="100"
					  ></el-table-column>
					  <el-table-column
					    label="院校"
					    prop="collegename"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="专业"
					    prop="majorname"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="学年"
					    prop="iyear"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="年级"
					    prop="grade"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="层次"
					    prop="levelval"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="班主任"
					    prop="followusername"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="函授站"
					    prop="guakaoschoolorgname"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="状态"
					    prop="xuejistatusval"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="项目类型"
					    prop="projecttype"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					  <el-table-column
					    label="教学点"
					    prop="schoolorgname"
					    align="center"
					    width="100"
					  >
					  </el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 5">
					<el-table
					  :data="watchDetailList.studentgenzonglist"
					  style="width: 100%"
					>
					  <el-table-column label="流水号" prop="id" align="center" />
					  <el-table-column
					    label="潜在学生"
					    prop="qianzainame"
					    align="center"
					  />
					  <el-table-column label="学生手机" prop="mobile" align="center" >
							<template slot-scope="scope">
								{{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
							</template>
						</el-table-column>
					  <el-table-column label="QQ" prop="qq" align="center" />
					  <el-table-column
					    label="跟进内容"
					    prop="followcontent"
					    align="center"
					  />
					  <el-table-column label="跟进时间" align="center">
					    <template slot-scope="scope">
					      {{ scope.row.inserttime | formatDate("YYYY-MM-DD HH:mm:ss") }}
					    </template>
					  </el-table-column>
					  <el-table-column label="跟进人" prop="username" align="center" />
					  <el-table-column
					    label="所属教学点"
					    prop="schoolorgname"
					    align="center"
					  />
					</el-table>
				</div>
			</div>
		</div>
	  <!-- 新增代码 -->
	  <Import
			  :importDialog="importDialog"
			  :importSaveUrl="importSaveUrl"
			  @onCloseImport="onCloseImport"
			  @getDataList="getDataList"
			  :downloadUrl="downloadUrl"
	  ></Import>
  </div>
</template>
<script src="../../../utils/createControl.js?r=${r}"></script>
<script>
import {
	CreateReport,
	CreatePrintViewerEx2,
	CreateDisplayViewerEx2,
	CreateDesignerEx,
	CreatePrintViewerEx,
	CreateDisplayViewerEx,
	CreateDisplayViewer,
	CreatePrintViewer,
	CreateDesigner,
	} from '@/utils/createControl';
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";
import Import from "@/components/Import/index.vue";

import {
  student_getstudent,
  studentpotential_show,
  studentregisterfeedetail_list,
  studentregisterfeedetail_show,
  studentregisterfeedetail_save,
  studentregisterfeedetail_delete,
  studentregisterfeedetail_feeback,
  studentregisterfeedetail_canfeeback,
  moneyintype2_getmoneyintype2byname,
  studentregisterfeedetail_registerfee,
  studentregisterfeedetail_exportdetail,
  studentfeeback_savesturegisterfeeback,
  studentregisterfeedetail_updateotherfee,
  studentregisterfeedetail_saveregisterfee,
  studentregisterfeedetail_paytoschoolmoney,
  studentregisterfeedetail_updatediscountfee,
  studentregisterfeedetail_updateextrasubmoney,
  studentregisterfeedetail_checkprint,
  studentregisterfeedetail_print,
	studentregisterfeedetail_checkstudentname,
	discount_loaddiscount,
	studentregisterfeedetail_printshoujuPaper,
	student_exportstudent,
	studentregisterfeedetail_input,
	studentregisterfeedetail_importpay
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import {
  _feebackflags,
	_isextrasubmoneyflags,
  _bankorcash,
  _approveflags,
  _approveflag,
  _chongzhanflag,
  _available,
} from "@/assets/js/filedValueFlag";

import {
  _studentregister_dialogresult,
  _moneyintype2_dialogresult,
  _moneyinitem_dialogresult,
  _account_dialogresult,
  _shouju_getshoujuinfo,
	_filedownload_save,
	 dic_combox,
} from "@/request/api/public";

export default {
  mixins: [part],
  name: "studentregisterfeedetail",
  components: { Export,Import },
  props: {
    account_dialogresult: {
      default: () => _account_dialogresult,
    },
    moneyintype2_dialogresult: {
      default: () => _moneyintype2_dialogresult,
    },
    moneyinitem_dialogresult: {
      default: () => _moneyinitem_dialogresult,
    },
    studentregister_dialogresult: {
      default: () => _studentregister_dialogresult,
    },
  },
  data() {
    return {
			disbtn: false,//新增和修改
			shoujuPaper: false,//收据弹框
			getCount: [],
			merge: {},
			discountid: null,//id
			//discountfee: null,//优惠
			showImportModal: false,//导入
			fileList: [], //上传的文件列表，仅作展示
			file: new FormData(), //上传的文件表单
			resTipC: "", //响应提示 object
			msgType: "", //提示信息类型
			currentTab: 0,
			currentClass: 0,
			tabList:['基本信息','学历报名记录','收费记录','培训记录','学籍记录 ','跟进记录'],
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      selectTable: {},
      selectSearchForm: {}, // 选择搜索
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      }, // 选择框分页
      isflag: {
        1: "已注册",
        0: "未注册",
      }, // 是否
      available: _available,
      chongzhanflag: _chongzhanflag,
      showAddModal: false, //新增
      showRegisterModal: false, // 注册收费
      showStudentNameModal: false, // 学生
      showMoneyinitemModal2: false, // 二级目录

      selectSchoolorgid: null,
      showDiscountModal: false, // 补优惠
      showExtrasubModal: false, // 补减免
      showOtherfeeModal: false, // 补减免
      rules: {
        money: [
          {
            required: true,
            message: "必填字段",
						trigger: "blur",
          },
        ],
        paytoschool2: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        remarks1: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        extrasubmoney2: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        remarks2: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        otherfee2: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        othershouju2: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        remarks3: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        studentname: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        feebackmoney: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        companyorgname: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        schoolorgname: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        shoujucode: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        feetypeval: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        bankorcash: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        accountname: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        itemname: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        type2name: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        zhaiyao: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        feebackmoney: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        feebacktime: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        reason: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        shoukuantime: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
        recvitemmoney: [
          {
            required: true,
            message: "必填字段",
            trigger: "blur",
          },
        ],
				iyear: [
					{
					  required: true,
					  message: "必填字段",
					  trigger: "blur",
					},
				],
				scorefile: [
					{
					  required: true,
					  message: "必填字段",
					  trigger: "blur",
					},
				]
      },
      feebackflags: _feebackflags,
			isextrasubmoneyflags: _isextrasubmoneyflags,
      showFeebackModal: false, //退费框
      showAccountModal: false, // 账户/现金
      showMoneyinitemModal: false, //支出明细框
      bankorcash: _bankorcash, // 账户/现金
      showMoneyinitemModal2: false, //支出二级明细框

      approveflags: _approveflags,
      approveflag: _approveflag,
			key: '',
      // 查看详情
      showDetailModal: false,
      vstudentpotential: {},
      watchDetailList: {},
      activeName: "vstudentpotential",
      tabId: null,
			discoundtList: [],
      showSelfPaymentModal: false,
			projecttypeList: [],//项目类型
			tableHeight: 500,
			feetype2List: [],
		importDialog: false, //导入框
		importSaveUrl:"",
		downloadUrl:"",
    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
		this.discoundtlist();
		this.dic_combox({
		  that: this,
		  typecode: "projecttype",
		  list: "projecttypeList",
		});
		this.dic_combox({
		  that: this,
		  typecode: "feetype2",
		  list: "feetype2List",
		});
		window.addEventListener("keyup", this.enterSelect);
  },
	mounted () {

	},
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild","dic_combox"]),
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
		visibleisextrans(e) {
			if(!e) {
				this.$refs.isextrans.blur();
			}
		},
		visibleisarrears(e) {
			if(!e) {
				this.$refs.isarrears.blur();
			}
		},
		visibleIsdis(e) {
			if(!e) {
				this.$refs.isdiscount.blur();
			}
		},
		visibleIsother(e) {
			if(!e) {
				this.$refs.isotherfee.blur();
			}
		},
		visibleprojecttype(e) {
			if(!e) {
				this.$refs.projecttype.blur();
			}
		},
		visibleName(e) {
			if(!e) {
				this.$refs.nameref.blur();
			}
		},
		reset() {
			this.searchForm = {
				studentname: null,
				mobile: null,
				collegename: null,
				schoolorgname: null,
				majorname: null,
				companyorgname: null,
				feebackflag: null,
				levelval: null,
				month: null,
				shoujucodes: null,
				grade: null,
				iyear: null,
				followusername: null,
				discountfee: null,
				isextrasubmoney: null,
				isarrears: null,
				isdiscountfee: null,
				isotherfee: null,
				projecttype: null,
				itemname: null
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		selectQuery() {
			this.selectSearchForm = {
				studentname: null,
				mobile: null,
				name: null,
				typename: null,
				type1name: null,
				accountname: null,
				bankname: null,
				cardno: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, null);
		},
    //  查看详情 tab切换 change
    handleClick() {
      this.btnShowDetail(this.tabId);
    },
		//项目类型 api
		leveltype() {
		  myRequest({
		    url: dic_combox,
		    data: {
		      typecode: "projecttype",
		    },
		  }).then((res) => {
		    if (res.data.code === "200") {
		      this.projecttypeList = res.data.data.list;
		    }
		  });
		},
    // 查看详情 clik
    btnShowDetail(id) {
      this.tabId = id;
      myRequest({
        url: studentpotential_show,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.watchDetailList = res.data.data;
          this.vstudentpotential = this.watchDetailList.vstudentpotential;
          this.showDetailModal = true;
        }
      });
    },
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
		//隐藏和显示
		handleShow(){
			this.showDetailModal = false;
		},
		//改变切换栏
		toggleTab(current) {
			this.currentTab = current;
			this.currentClass = current;
		},
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: studentregisterfeedetail_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
	//打印收据纸质
	btnPrint2() {
		selectCheck(this.multipleSelection, "操作", false, ()=> {
			this.selectShouju(this.multipleSelection[0].id)
		})
	},
	//检查可否打印收据 api 纸质版本
	selectShouju() {
		myRequest({
			url: studentregisterfeedetail_checkprint,
			data: {
				id: this.multipleSelection[0].id,
			}
		}).then((res) => {
			if(res.data.code === "200") {
				this.shoujuPaper = true;
				this.printshoujuPaper();
			}
		})
	},
	printshoujuPaper() {
		myRequest({
		       url: studentregisterfeedetail_printshoujuPaper,
		       data:{
		         id: this.multipleSelection[0].id,
						shoujuid: this.multipleSelection[0].shoujuid
		         	}
		     }).then((res) => {
		       if (res.data.code === "200") {
								this.getDataList();
							this.tshouju = res.data.data.tshouju;
		       		this.vshouju = res.data.data.vshouju;
							this.vmoneyin = res.data.data.vmoneyin;
							this.vstudentregisterfeedetail = res.data.data.vstudentregisterfeedetail;
							res.data.data.vstudentregisterfeedetail.shoukuantime = formatDare(
								this.vstudentregisterfeedetail.shoukuantime,
							  "YYYY-MM-DD HH:mm:ss"
							);
							this.sessionuser = res.data.data.sessionuser;
		          var ReportViewer = document.getElementById("ReportViewer");
		          ReportViewer.Stop();    
		          var Report = ReportViewer.Report;
		          var Recordset = Report.DetailGrid.Recordset;

		          //获得参数
							var ppzh= ReportViewer.Report.ParameterByName("pzh");
							var pname= ReportViewer.Report.ParameterByName("name");
							var pinserttime= ReportViewer.Report.ParameterByName("inserttime");
							var pgrade= ReportViewer.Report.ParameterByName("grade");
							var plevel= ReportViewer.Report.ParameterByName("level");
							var pmajor= ReportViewer.Report.ParameterByName("major");
							var premark= ReportViewer.Report.ParameterByName("remark");
							var pshenheren= ReportViewer.Report.ParameterByName("shenheren");
							var pkuaiji= ReportViewer.Report.ParameterByName("kuaiji");
							var pshoukuairen= ReportViewer.Report.ParameterByName("shoukuairen");
							var pkaipiaoren= ReportViewer.Report.ParameterByName("kaipiaoren");
							var psfzhm= ReportViewer.Report.ParameterByName("sfzhm");
							var bankorcash= ReportViewer.Report.ParameterByName("bankorcash");
							//填充参数
							ppzh.AsString= this.tshouju.code;
							pname.AsString=	this.vstudentregisterfeedetail.studentname;
							pinserttime.AsString=	this.vstudentregisterfeedetail.shoukuantime;	//收款时间
							pgrade.AsString=	this.vstudentregisterfeedetail.grade == null ? "" : this.vstudentregisterfeedetail.grade;//年级
							plevel.AsString=	this.vstudentregisterfeedetail.levelval;
							premark.AsString=	this.vstudentregisterfeedetail.shoukuanremark + '* 德立教育集团投诉与建议: 020-89226488';
							pmajor.AsString=	this.vstudentregisterfeedetail.majorname;
							pshoukuairen.AsString= this.vstudentregisterfeedetail.followuserworknumber;
							pkaipiaoren.AsString=this.sessionuser.code;
							psfzhm.AsString=	this.vstudentregisterfeedetail.studentidcard == null ? '' : this.vstudentregisterfeedetail.studentidcard;
							if(this.vmoneyin.bankorcash ==1){
								bankorcash.AsString="（现金）";
							}else if(this.vmoneyin.bankorcash ==0){
								bankorcash.AsString="（转账）";
								}


							//获得字段
							var fcollege = ReportViewer.Report.FieldByName("college");
							var fitem = ReportViewer.Report.FieldByName("item");
							var fprice = ReportViewer.Report.FieldByName("price");

							var fitem0 = ReportViewer.Report.FieldByName("item0");
							var fprice0 = ReportViewer.Report.FieldByName("price0");
							var yuan0 = ReportViewer.Report.FieldByName("yuan0");
							var yuan = ReportViewer.Report.FieldByName("yuan");
							var maohao0 = ReportViewer.Report.FieldByName("maohao0");
							var maohao = ReportViewer.Report.FieldByName("maohao");

							//填充字段

							Report.PrepareRecordset();

							Recordset.Append();
							fitem0.AsString =	this.vstudentregisterfeedetail.itemname;
							fprice0.AsFloat =	this.vstudentregisterfeedetail.money; //应收金额
							maohao0.AsString ="：" ;
							yuan0.AsString ="元" ;
							fcollege.AsString = this.vstudentregisterfeedetail.collegename;
							fitem.AsString =	this.vmoneyin.itemmoneyinitemname;
							maohao.AsString ="："
							fprice.AsFloat =this.vmoneyin.amount;
							yuan.AsString ="元" ;
							Recordset.Post();

						 if(this.vstudentregisterfeedetail.discountfee !== 0){
								Recordset.Append();
								fitem0.AsString ="活动优惠" ;
								maohao0.AsString ="："
								fprice0.AsFloat = 0 - this.vstudentregisterfeedetail.discountfee;
								yuan0.AsString ="元" ;
								Recordset.Post();
						   }

						   if(0-this.vstudentregisterfeedetail.extrasubmoney <0){
								Recordset.Append();
								fitem0.AsString ="减免" ;
								maohao0.AsString ="："
								fprice0.AsFloat =0-this.vstudentregisterfeedetail.extrasubmoney;
								yuan0.AsString ="元" ;
								Recordset.Post();
						   }
						   if(0-this.vstudentregisterfeedetail.studentfee <0){
								Recordset.Append();
								fitem0.AsString ="预交学位费" ;
								maohao0.AsString ="："
								fprice0.AsFloat =	0-this.vstudentregisterfeedetail.studentfee;
								yuan0.AsString ="元" ;
								Recordset.Post();
						   }
						   if(0- this.vstudentregisterfeedetail.otherfee <0){
								Recordset.Append();
								fitem0.AsString ="预交其它费" ;
								maohao0.AsString ="："
								fprice0.AsFloat = 0-this.vstudentregisterfeedetail.otherfee;
								yuan0.AsString ="元" ;
								Recordset.Post();
						   }
		          	ReportViewer.Start();
		       }
		  });
	},
    //打印收据 click
    btnPrint() {
      selectCheck(this.multipleSelection, "操作", false, () => {
        this.checkprintshouju(this.multipleSelection[0]);
      });
    },
    //检查可否打印收据 api
    checkprintshouju(row) {
      myRequest({
        url: studentregisterfeedetail_checkprint,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.printReceipt(row);
        }
      });
    },
    //打印收据 api
    printReceipt(row) {
      myRequest({
        url: studentregisterfeedetail_print,
        data: {
          id: row.id,
          shoujuid: row.shoujuid,
          shoujucode: row.shoujucode,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          window.open(
            this.$url.upload +
              "/shouju/" +
              row.id +
              res.data.data.tshouju.code +
              ".pdf"
          );
        }
      });
    },
    // 新增 click
    btnAdd() {
			this.dialogTitle = "添加:";
			this.disbtn = false;
      this.dynamicValidateForm = {
        type2name: null,
        studentname: null,
        itemname: null,
      };
      this.showAddModal = true;
    },

    // 修改 click
    btnEdit() {
			this.dialogTitle = "修改:";
			this.disbtn = true;
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoute(this.multipleSelection[0].id);
      });
    },

    // 修改 click
    editRoute(id) {
      myRequest({
        url: studentregisterfeedetail_show,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.dynamicValidateForm = res.data.data;
          delete this.dynamicValidateForm.inserttime;
					delete this.dynamicValidateForm.shoukuantime; //shoukuantime 该字段不用传
					delete this.dynamicValidateForm.shoujucodes;
        }
      });
    },

    // 新增修改保存 click api
    submitAddOrEditForm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: studentregisterfeedetail_save,
              data: this.dynamicValidateForm,
            },
            {
              that: this,
              isRefTableList: true,
              modal: "showAddModal",
            }
          );
        }
      });
    },

    // 补优惠 click
    btnDiscount() {
      selectCheck(this.multipleSelection, "补优惠", false, () => {
				this.studentregisterfeedetail(this.multipleSelection[0].id);
      });
    },
		studentregisterfeedetail(id) {
			myRequest({
			  url: studentregisterfeedetail_input,
			  data: {
			    id: id,
			  },
			}).then((res) => {
			  if (res.data.code === "200") {
					this.dynamicValidateForm  = res.data.data
					this.showDiscountModal = true;
			    this.discoundtlist();
			  }
			});
		},

    // 补优惠 click api
    onDiscountSumbit(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
					if(this.getCount.length != []) {
							confirmCallBack({
							    title: "提示",
							    content: "该学生有优惠卷是否下一步！",
							    success: () => {
											this.saveCount(this.lastdisfee());
							    },
							    fail: () => {
							      messageTip({
							        message: "取消操作",
							        type: "info",
							      });
							    },
							});
						} else {
							this.saveCount(this.lastdisfee());
						}
        }
      });
    },
		saveCount() {
			myRequest(
			  {
			    url: studentregisterfeedetail_updatediscountfee,
			    data: this.dynamicValidateForm,
			  },
			  {
			    that: this,
			    isRefTableList: true,
			    modal: "showDiscountModal",
			  }
			);
		},
		lastdisfee() {
			if(this.dynamicValidateForm.dissfee == '') {
				this.dynamicValidateForm.dissfee = 0;
			}
		},
		//名单校验 click
		btnCheck() {
			this.showImportModal = true;
			this.fileList = []
			this.resTipC = "";
			this.$set(this.dynamicValidateForm, "scorefile", "");
		},
		//文件上传改变的钩子
		handleChange(file, fileList) {
		  this.file = new FormData();
		  this.file.append("scorefile", file.raw); // 传文件
		  this.fileList = [{ name: file.name, url: file.name }];
		  this.dynamicValidateForm.scorefile = file.raw.name;
		},
		//文件上传删除
		onScorefileRemove(file, FileList){
			this.dynamicValidateForm.scorefile = ''
			this.file.delete('scorefile')
		},
		//导入文件-下载模板 click
		downloadTemplate(url){
			myRequest({
				url: _filedownload_save,
				data: {
					fileurl: url,
				},
			}).then((res) => {
				if (res.status === 200) {
					window.open(this.$url.Freemarker + _filedownload_save + "?fileurl=" + url)
				}
			});
		},
		//导入确定
		submitImportForm(dynamicValidateForm) {
			this.$refs[dynamicValidateForm].validate((valid) => {
				if(valid) {
					 myRequest({
						method: "post",
						url:studentregisterfeedetail_checkstudentname,
						data: this.file,
						headers: {
						  "Content-Type": "multipart/form-data",
						},
					}).then((res) =>{
						this.resTipC = res.data.data;
						if(res.data.code === "200" && res.data.object === null) {
							this.getDataList();
							this.showImportModal = false;
						}
					})
				}
			})
		},
    // 补减免 click
    btnExtrasub() {
      selectCheck(this.multipleSelection, "补减免", false, () => {
        this.dynamicValidateForm = {
          id: this.multipleSelection[0].id,
        };
        this.showExtrasubModal = true;
      });
    },

    // 补减免 api click
    onExtrasubSumbit(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: studentregisterfeedetail_updateextrasubmoney,
              data: this.dynamicValidateForm,
            },
            {
              that: this,
              isRefTableList: true,
              modal: "showExtrasubModal",
            }
          );
        }
      });
    },

    // 补其它 click
    btnOtherfee() {
      selectCheck(this.multipleSelection, "补其它", false, () => {
        this.dynamicValidateForm = {
          id: this.multipleSelection[0].id,
        };
        this.showOtherfeeModal = true;
      });
    },

    // 补其它 api click
    onOtherfeeSumbit(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: studentregisterfeedetail_updateotherfee,
              data: this.dynamicValidateForm,
            },
            {
              that: this,
              isRefTableList: true,
              modal: "showOtherfeeModal",
            }
          );
        }
      });
    },

    // 自行缴费 click
    btnSelfPayment() {
      selectCheck(this.multipleSelection, "自行缴费", false, () => {
        this.dynamicValidateForm = {
          id: this.multipleSelection[0].id,
        };
        this.showSelfPaymentModal = true;
      });
    },

    // 自行缴费 click api
    onSelfPaymentSumbit(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: studentregisterfeedetail_paytoschoolmoney,
              data: this.dynamicValidateForm,
            },
            {
              that: this,
              isRefTableList: true,
              modal: "showSelfPaymentModal",
            }
          );
        }
      });
    },

    //切换选项表格页
    handleSelectChange(val, data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl, null, data);
			this.onCloseSelect();
    },
    //选项表格 click（银行, 二级, 明细）
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data) {
      this.selectPageInfo.pageindex = 1;
      this.onSelectData(url, modal, data);
    },
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },

    //审批 click
    btnApproval() {
      selectCheck(this.multipleSelection, "审批", true, () => {
        this.approvecheck(this.multipleSelection.map((i) => i.id));
      });
    },
    //退费 click
    btnReturnmoney() {
      selectCheck(this.multipleSelection, "退费", false, () => {
        this.checkFeeback();
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //删除 click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //提交退费 click
    onFeebackClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.feeBack();
        }
      });
    },

    //保存退费 api
    feeBack() {
      myRequest(
        {
          url: studentfeeback_savesturegisterfeeback,
          data: this.dynamicValidateForm,
        },
        {
          modal: "showFeebackModal",
          that: this,
          isRefTableList: true,
        }
      );
    },
    // 确定学生
    onConfirmStudent() {
      selectCheck(this.multipleSelection, "确定学生", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "studentname",
          this.multipleSelection[0].studentname
        );
        this.$set(
          this.dynamicValidateForm,
          "levelval",
          this.multipleSelection[0].levelval
        );
        this.$set(
          this.dynamicValidateForm,
          "collegename",
          this.multipleSelection[0].collegename
        );
        this.$set(
          this.dynamicValidateForm,
          "studytypeval",
          this.multipleSelection[0].studytypeval
        );
				this.dynamicValidateForm.collegemajorid = this.$set(
				  this.dynamicValidateForm,
				  "majorid",
				  this.multipleSelection[0].majorid
				);
        this.$set(
          this.dynamicValidateForm,
          "grade",
          this.multipleSelection[0].grade
        );
        this.$set(
          this.dynamicValidateForm,
          "majorname",
          this.multipleSelection[0].majorname
        );
        this.$set(
          this.dynamicValidateForm,
          "projecttype",
          this.multipleSelection[0].projecttype
        );
        this.$set(
          this.dynamicValidateForm,
          "schoolorgname",
          this.multipleSelection[0].schoolorgname
        );
				this.$set(
				  this.dynamicValidateForm,
				  "xuejistatusval",
				  this.multipleSelection[0].xuejistatusval
				);
        this.dynamicValidateForm.registerid = this.multipleSelection[0].id;
        this.dynamicValidateForm.qianzaiid =
          this.multipleSelection[0].qianzaiid;
        this.dynamicValidateForm.studentid =
          this.multipleSelection[0].studentid;
        this.dynamicValidateForm.schoolorgid =
          this.multipleSelection[0].schoolorgid;
        this.dynamicValidateForm.companyorgid =
          this.multipleSelection[0].companyorgid;
        this.getFreeData(this.multipleSelection[0].studentid);
        this.getType2name(this.multipleSelection[0].collegename);
				delete this.dynamicValidateForm.shoujucodes;
        this.showStudentNameModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },

    // 获取费用 api
    getFreeData(id) {
      myRequest({
        url: student_getstudent,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm.shouldrecvmoney =
            res.data.data.vstudent.shouldrecvmoney;
          this.dynamicValidateForm.realrecvmoney =
            res.data.data.vstudent.realrecvmoney;
          this.dynamicValidateForm.feebackmoney =
            res.data.data.vstudent.feebackmoney;
          this.dynamicValidateForm.shoujucodes =
            res.data.data.vstudent.shoujucodes;
        }
      });
    },

    // 获取二级目录 api
    getType2name(collegename) {
      myRequest({
        url: moneyintype2_getmoneyintype2byname,
        data: {
          name: collegename,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm.type2id = res.data.data.vmoneyintype2?.id;
          this.dynamicValidateForm.type2name =
            res.data.data.vmoneyintype2?.name;
        }
      });
    },

    //确认账户 click
    onConfirmAccount() {
      selectCheck(this.multipleSelection, "确认账户", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "bankorcash",
          this.multipleSelection[0].bankorcash
        );
        this.$set(
          this.dynamicValidateForm,
          "accountname",
          this.multipleSelection[0].accountname
        );
        this.$set(
          this.dynamicValidateForm,
          "cardno",
          this.multipleSelection[0].cardno
        );
        this.dynamicValidateForm.accountid = this.multipleSelection[0].id;
        this.showAccountModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },
    //确认支出明细 click
    onConfirmMoneyinitem() {
      selectCheck(this.multipleSelection, "确认支出明细", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "itemname",
          this.multipleSelection[0].name
        );
        this.dynamicValidateForm.itemid = this.multipleSelection[0].id;
        this.showMoneyinitemModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },
    //确认支出二级明细 click
    onConfirmMoneyinitem2() {
      selectCheck(this.multipleSelection, "确认支出二级明细", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "type2name",
          this.multipleSelection[0].name
        );
        this.$set(
          this.dynamicValidateForm,
          "typename",
          this.multipleSelection[0].typename
        );
        this.$set(
          this.dynamicValidateForm,
          "type1name",
          this.multipleSelection[0].type1name
        );
        this.dynamicValidateForm.typeid = this.multipleSelection[0].typeid;
        this.dynamicValidateForm.type1id = this.multipleSelection[0].type1id;
        this.dynamicValidateForm.type2id = this.multipleSelection[0].id;
        this.showMoneyinitemModal2 = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },

    // 注册收费 click
    btnRegister() {
      selectCheck(this.multipleSelection, "注册收费", false, () => {
        this.registerRoute(this.multipleSelection[0].id);
      });
    },

    //获取收据编号  api
    getshoujuinfo() {
      myRequest({
        url: _shouju_getshoujuinfo,
        data: {
          schoolorgid: this.dynamicValidateForm.schoolorgid,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm.shoujuid = res.data.data.tshouju.id;
          this.dynamicValidateForm.shoujucode = res.data.data.tshouju.code;
        }
      });
    },

    // 注册收费路由页 api
    registerRoute(id) {
      myRequest({
        url: studentregisterfeedetail_registerfee,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data;
          delete this.dynamicValidateForm.inserttime;
          this.dynamicValidateForm.shoukuantime = formatDare(
            this.dynamicValidateForm.shoukuantime,
            "YYYY-MM-DD HH:mm:ss"
          );
          this.dynamicValidateForm.recvitemmoney = res.data.data.recvitemmoney || '';
					this.dynamicValidateForm.discountfee = res.data.data.discountfee || '';

          this.showRegisterModal = true;
					this.discoundtlist();
        }
      });
    },
		onCountfee(data) {
			 const { value, label } = data
			  this.dynamicValidateForm.discountid = value
			  this.dynamicValidateForm.discountfee = label
			console.log(this.dynamicValidateForm.discountfee, this.dynamicValidateForm.discountid, '这个是onCountfee')
		},
		changefee(data) {
			const { value, label } = data
			  this.dynamicValidateForm.discountid = value
			  this.dynamicValidateForm.dissfee = label
			console.log(this.dynamicValidateForm.dissfee, this.dynamicValidateForm.discountid, '这个是onCountfee')
		},
		lastFee() {
			if(this.dynamicValidateForm.discountfee == '') {
				this.dynamicValidateForm.discountfee = 0;
			}
		},
		//加载优惠卷
		discoundtlist() {
			myRequest({
				url: discount_loaddiscount,
				data: {
					studentid: this.dynamicValidateForm.studentid,
					iyear:this.dynamicValidateForm.iyear,
					grade:this.dynamicValidateForm.grade
				}
			}).then((res) => {
				if(res.data.code === "200") {
					this.getCount = res.data.data.list;
				}
			})
		},

		//优惠卷弹框信息
		saveDiscount() {
			myRequest(
				{
					url: studentregisterfeedetail_saveregisterfee,
					data: this.dynamicValidateForm,
				},
				{
					that: this,
					isRefTableList: true,
					modal: "showRegisterModal",
				}
			);
		},
		submitRegisterForm(dynamicValidateForm) {
			this.$refs[dynamicValidateForm].validate((valid) => {
			  if (valid) {
					if(this.getCount.length != []) {
							confirmCallBack({
							    title: "提示",
							    content: "该学生有优惠卷是否下一步！",
							    success: () => {
											this.saveDiscount(this.lastFee());
							    },
							    fail: () => {
							      messageTip({
							        message: "取消操作",
							        type: "info",
							      });
							    },
							});
						} else {
							this.saveDiscount(this.lastFee());
						}
				}
				});
		},
    //退费校验 api
    checkFeeback() {
      myRequest(
        {
          method: "post",
          url: studentregisterfeedetail_canfeeback,
          data: this.$qs.stringify(
            {
              ids: this.multipleSelection.map((i) => i.id),
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          that: this,
          methodName: "feeBackInput",
        }
      );
    },
    //退费路由页 api
    feeBackInput() {
      this.itemId = this.multipleSelection[0].id;
      myRequest({
        url: studentregisterfeedetail_feeback,
        data: {
          id: this.itemId,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data.tstudentregisterfeedetail;
					delete this.dynamicValidateForm.feebacktime;
          delete this.dynamicValidateForm.inserttime;
          delete this.dynamicValidateForm.shoukuantime;
          delete this.dynamicValidateForm.deleteflag;
          this.showFeebackModal = true;
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: studentregisterfeedetail_delete,
          data: this.$qs.stringify(
            {
              id: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

	  //自缴金额导入
	  btnImport() {
		  this.importSaveUrl = studentregisterfeedetail_importpay;
		  this.downloadUrl = "/download/业务管理/学生自缴和学位费导入模板.xls";
		  this.importDialog = true;
	  },
	  //关闭导入框
	  onCloseImport(bool) {
		  this.importDialog = bool;
	  },

    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    //导出总表 click api
    btnExportDetail() {
      confirmCallBack({
        title: "提示",
        content: "根据搜索条件进行导出。",
        success: () => {
          window.location.href =
            this.$url.Freemarker +
            studentregisterfeedetail_exportdetail +
            `?${createObjParams(this.searchForm)}`;
        },
        fail: () => {
          messageTip({
            message: "取消操作",
            type: "info",
          });
        },
      });
    },
  },

};
</script>

<style  lang="scss">
	@import "../../../assets/css/pulid.scss";
</style>
